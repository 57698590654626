<template>
  <el-main>
    <el-form class="el-form-search" label-width="100px">
      <el-form-item label="用户信息：">
        <el-input
          size="small"
          v-model="searchItem.orderNum"
          placeholder="请输入用户昵称/姓名/手机"
        ></el-input>
      </el-form-item>
      <el-form-item label="审核状态：">
        <el-select v-model="searchItem.proxyStatus" size="small" placeholder="请选择">
          <el-option
            v-for="item in proxyStatusList"
            :key="item.status"
            :label="item.name"
            :value="item.status"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="提交时间：">
        <el-date-picker
          v-model="searchItem.time"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          size="small"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="所属地区：">
        <el-select v-model="searchItem.cityId" size="small" placeholder="请选择">
          <el-option
            v-for="item in cityList"
            :key="item.city_id"
            :label="item.city_name"
            :value="item.city_id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label=" " label-width="30px">
        <el-button type="primary" size="small" @click="getShopList('search')">搜索</el-button>
        <el-button type="text" size="small" @click="cancelSearch">清空搜索条件</el-button>
      </el-form-item>
    </el-form>
    <el-table :data="dataList" :header-cell-style="{ 'background-color': '#F8F9FA' }">
      <template slot="empty">
        <No />
      </template>
      <el-table-column label="用户信息" align="center">
        <template v-slot="{ row }">
          <UserInfo :name="row.member.nickname" :avatar="row.member.avatar"></UserInfo>
        </template>
      </el-table-column>
      <el-table-column prop="name" label="用户姓名" align="center"> </el-table-column>
      <el-table-column prop="contacts_phone" label="手机号码" align="center"> </el-table-column>
      <el-table-column label="累计邀请员工" align="center">
        <template v-slot="{ row }">
          {{ Number(row.invite_staff_num) }}
        </template>
      </el-table-column>
      <el-table-column label="申请区域" align="center">
        <template v-slot="{ row }">
          {{ row.province_name + row.city_name + row.area_name }}
        </template>
      </el-table-column>
      <el-table-column label="状态" align="center">
        <template v-slot="{ row }">
          <div :style="{ color: row.status == 0 || row.status == -2 ? '#ff6323' : '#3370ff' }">
            {{
              row.status == -1
                ? '驳回'
                : row.status == 0
                ? '待审核'
                : row.status == 1
                ? '通过'
                : '已失效'
            }}
          </div>
        </template>
      </el-table-column>
      <el-table-column label="提交时间" align="center">
        <template v-slot="{ row }">{{ getDateformat(row.create_time) }}</template>
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template v-slot="{ row }">
          <el-button type="text" size="small" @click="examineItem(row)" v-if="!row.status"
            >审核</el-button
          >
          <el-button type="text" size="small" @click="lookExamineRecord(row)">审核记录</el-button>
          <el-button type="text" size="small" v-if="row.status == 1" @click="changeEmployee(row)"
            >修改为员工</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <Paging :total="total" :page="page" :pageNum="rows" @updatePageNum="updateData"></Paging>
    <el-dialog title="审核" :visible.sync="examineDialogFlag" width="400px" :closed="closeExamine">
      <div class="examineBox">
        <div class="title">请选择审核的结果：</div>
        <el-radio-group class="title" v-model="examineStatus">
          <el-radio :label="1">通过</el-radio>
          <el-radio :label="-1">驳回</el-radio>
        </el-radio-group>
        <div class="title" v-if="examineStatus == -1">
          <span style="color: red">*</span>请添加驳回原因：
        </div>
        <el-input
          v-if="examineStatus == -1"
          type="textarea"
          :rows="5"
          placeholder="请输入内容"
          v-model="rejectReason"
        >
        </el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeExamine()">取 消</el-button>
        <el-button type="primary" @click="changeStatus()">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="审核记录" :visible.sync="examineListDialogFlag" width="800px">
      <el-table :data="examineList" :header-cell-style="{ 'background-color': '#F8F9FA' }">
        <template slot="empty">
          <No />
        </template>
        <el-table-column label="审核时间" align="center">
          <template v-slot="{ row }">
            {{ getDateformat(row.create_time) }}
          </template>
        </el-table-column>
        <el-table-column label="审核人员" align="center">
          <template v-slot="{ row }">
            {{ row.operator_name || '--' }}
          </template>
        </el-table-column>
        <el-table-column label="审核结果" align="center">
          <template v-slot="{ row }">
            <div :style="{ color: row.status == 0 || row.status == -2 ? '#ff6323' : '#3370ff' }">
              {{
                row.status == -1
                  ? '驳回'
                  : row.status == 0
                  ? '待审核'
                  : row.status == 1
                  ? '通过'
                  : '已失效'
              }}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="反馈详情" align="center">
          <template v-slot="{ row }">
            {{ row.reason || '--' }}
          </template>
        </el-table-column>
      </el-table>
      <Paging
        :total="totalList"
        :page="pageList"
        :pageNum="rowList"
        @updatePageNum="updateDataList"
      ></Paging>
    </el-dialog>
  </el-main>
</template>

<script>
import Paging from '@/components/paging'
import { getDateformat } from '@/util/getDate.js'
import UserInfo from '@/components/userInfo'
export default {
  components: {
    Paging,
    UserInfo
  },
  data() {
    return {
      getDateformat,
      searchItem: {
        proxyName: '',
        time: [],
        proxyStatus: -3,
        cityId: 0
      },
      cityList: [{ city_name: '全部', city_id: 0 }],
      proxyStatusList: [
        { status: -3, name: '全部' },
        { status: 0, name: '审核中' },
        { status: 1, name: '通过' },
        { status: -1, name: '拒绝' },
        { status: -2, name: '已失效' }
      ],
      dataList: [],
      page: 1,
      rows: 10,
      total: 0,
      // 审核弹框控制器
      examineDialogFlag: false,
      // 当前审核的数据的id
      nowExamineItem: null,
      // 选择的审核状态
      examineStatus: 1,
      // 驳回原因
      rejectReason: null,
      // 审核记录弹框
      examineListDialogFlag: false,
      examineListItem: null,
      examineList: [],
      pageList: 1,
      rowList: 10,
      totalList: 0
    }
  },
  methods: {
    updateData(val, status) {
      if (status == 0) {
        this.rows = val
      } else {
        this.page = val
      }
      this.getShopList()
    },
    updateDataList(val, status) {
      if (status == 0) {
        this.rowList = val
      } else {
        this.pageList = val
      }
    },
    cancelSearch() {
      this.page = 1
      this.searchItem = {
        proxyName: '',
        time: [],
        proxyStatus: -3,
        cityId: 0
      }
      this.getShopList()
    },
    // 获取页面列表信息
    getShopList(style) {
      if (style) {
        this.page = 1
      }
      let obj = {
        page: this.page,
        rows: this.rows
      }
      let time = this.searchItem.time
      if (time.length) {
        // 如果需要按时间查询
        obj.create_time = [Math.ceil(time[0].getTime() / 1000), Math.ceil(time[1].getTime() / 1000)]
        if (obj.create_time[0] == obj.create_time[1]) {
          obj.create_time[1] += 60 * 60 * 24 - 1
        }
      }
      if (this.searchItem.proxyName) {
        obj.name = this.searchItem.proxyName
      }
      if (this.searchItem.cityId) {
        obj.city_id = this.searchItem.cityId
      }
      if (this.searchItem.proxyStatus != -3) {
        obj.status = this.searchItem.proxyStatus
      }
      this.$axios.post(this.$api.push.regionalShareholders.list, obj).then((res) => {
        if (res.code === 0) {
          this.dataList = res.result.list
          this.total = res.result.total_number
        }
      })
    },
    getCity() {
      this.$axios.post(this.$api.samecity.selectCityList).then((res) => {
        if (res.code == 0) {
          this.cityList = this.cityList.concat(res.result.list)
        }
      })
    },
    changeStatus() {
      let obj = {
        id: this.nowExamineItem.id,
        status: this.examineStatus
      }
      if (this.examineStatus == -1) {
        if (!this.rejectReason) {
          this.$message.warning('请填写驳回原因')
          return
        } else {
          obj.reason = this.rejectReason
        }
      }
      this.$axios.post(this.$api.push.regionalShareholders.updateStatus, obj).then((res) => {
        if (res.code == 0) {
          this.$message.success('审核成功')
          this.nowExamineItem.status = this.examineStatus
          this.closeExamine()
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    closeExamine() {
      this.examineDialogFlag = false
      this.nowExamineItem = null
      this.xamineStatus = 1
      this.rejectReason = null
    },
    // 打开审核弹框
    examineItem(row) {
      this.examineDialogFlag = true
      this.nowExamineItem = row
    },
    // 查看审核记录
    lookExamineRecord(row) {
      this.examineListItem = row
      this.getExamineList()
      this.examineListDialogFlag = true
    },
    getExamineList() {
      this.$axios
        .post(this.$api.push.regionalShareholders.getLog, {
          id: this.examineListItem.id,
          page: this.pageList,
          rows: this.rowList
        })
        .then((res) => {
          if (res.code == 0) {
            this.examineList = res.result.list
            this.totalList = res.result.total
          } else {
            this.$message.error(res.msg)
          }
        })
    },
    // 修改为员工
    changeEmployee(row) {
      this.$confirm('此操作将该代理降级为普通员工, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.$axios
            .post(this.$api.push.regionalShareholders.updateIdentity, {
              id: row.id,
              status: -2
            })
            .then((res) => {
              if (res.code == 0) {
                row.status = -2
                this.$message.success('降级成功')
              } else {
                this.$message.error('降级失败')
              }
            })
        })
        .catch(() => {})
    }
  },
  created() {
    this.getCity()
    this.getShopList()
  }
}
</script>

<style lang="less" scoped>
.el-main {
  background-color: #fff;
  & > .el-button {
    margin-bottom: 10px;
  }
  .examineBox {
    .title {
      margin-bottom: 20px;
    }
  }
}
</style>
